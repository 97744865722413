var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: {
        icon: "mdi-calendar-multiple-check",
        title: "Selecionar Período"
      }
    },
    [
      _c(
        "v-container",
        [
          _vm.grupoVisible
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _vm.$vuetify.breakpoint.name == "xs" ||
                      _vm.$vuetify.breakpoint.name == "sm"
                        ? _c("v-select", {
                            attrs: {
                              items: _vm.itemsGrupo,
                              label: "Grupo Educacional",
                              "item-text": "name",
                              "item-id": "id",
                              "return-object": "",
                              required: ""
                            },
                            on: { change: _vm.changeGrupo },
                            model: {
                              value: _vm.selectedGrupo,
                              callback: function($$v) {
                                _vm.selectedGrupo = $$v
                              },
                              expression: "selectedGrupo"
                            }
                          })
                        : _c("v-autocomplete", {
                            attrs: {
                              items: _vm.itemsGrupo,
                              label: "Grupo Educacional",
                              "item-text": "name",
                              "item-id": "id",
                              "return-object": "",
                              required: "",
                              autocomplete: ""
                            },
                            on: { change: _vm.changeGrupo },
                            model: {
                              value: _vm.selectedGrupo,
                              callback: function($$v) {
                                _vm.selectedGrupo = $$v
                              },
                              expression: "selectedGrupo"
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "12" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.itemsIes,
                      label: "Instituição",
                      "item-text": "name",
                      "return-object": "",
                      required: ""
                    },
                    on: { change: _vm.changeIes },
                    model: {
                      value: _vm.selectedIes,
                      callback: function($$v) {
                        _vm.selectedIes = $$v
                      },
                      expression: "selectedIes"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "12" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.itemsPeriodo,
                      label: "Período Letivo",
                      "item-text": "name",
                      "return-object": "",
                      required: ""
                    },
                    model: {
                      value: _vm.selectedPeriodo,
                      callback: function($$v) {
                        _vm.selectedPeriodo = $$v
                      },
                      expression: "selectedPeriodo"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.isSelectedAnima
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c("v-combobox", {
                        attrs: {
                          items: _vm.itemsBrandFiltered,
                          label: "Marca",
                          multiple: "",
                          clearable: "",
                          "item-id": "id",
                          "item-text": "name",
                          chips: ""
                        },
                        on: { input: _vm.changeBrand },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend-item",
                              fn: function() {
                                return [
                                  _c("v-checkbox", {
                                    staticClass: "ml-2",
                                    attrs: { label: "Todos" },
                                    on: { change: _vm.changeBrandAll },
                                    model: {
                                      value: _vm.checkboxAllBrands,
                                      callback: function($$v) {
                                        _vm.checkboxAllBrands = $$v
                                      },
                                      expression: "checkboxAllBrands"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "selection",
                              fn: function(ref) {
                                var attrs = ref.attrs
                                var item = ref.item
                                var parent = ref.parent
                                var selected = ref.selected
                                return [
                                  item === Object(item)
                                    ? _c(
                                        "v-chip",
                                        _vm._b(
                                          {
                                            attrs: {
                                              "input-value": selected,
                                              color: item.color
                                            }
                                          },
                                          "v-chip",
                                          attrs,
                                          false
                                        ),
                                        [
                                          _c("span", { staticClass: "pr-2" }, [
                                            _vm._v(
                                              " " + _vm._s(item.name) + " "
                                            )
                                          ]),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { "x-small": "" },
                                              on: {
                                                click: function($event) {
                                                  parent.selectItem(item)
                                                  _vm.checkboxAllBrands = false
                                                }
                                              }
                                            },
                                            [_vm._v("close")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1097286240
                        ),
                        model: {
                          value: _vm.chipsBrands,
                          callback: function($$v) {
                            _vm.chipsBrands = $$v
                          },
                          expression: "chipsBrands"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "12" } },
                [
                  _c("v-combobox", {
                    attrs: {
                      items: _vm.filteredItemsCampus,
                      label: "Campus",
                      multiple: "",
                      clearable: "",
                      "item-id": "id",
                      "item-text": "abbreviation",
                      chips: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.isSelectedAnima
                          ? {
                              key: "prepend-item",
                              fn: function() {
                                return [
                                  _c("v-checkbox", {
                                    staticClass: "ml-2",
                                    attrs: { label: "Todos" },
                                    on: {
                                      change: function($event) {
                                        _vm.checkboxAllCampuses
                                          ? (_vm.chipsCampuses =
                                              _vm.filteredItemsCampus)
                                          : (_vm.chipsCampuses = [])
                                      }
                                    },
                                    model: {
                                      value: _vm.checkboxAllCampuses,
                                      callback: function($$v) {
                                        _vm.checkboxAllCampuses = $$v
                                      },
                                      expression: "checkboxAllCampuses"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          : null,
                        {
                          key: "selection",
                          fn: function(ref) {
                            var index = ref.index
                            var attrs = ref.attrs
                            var item = ref.item
                            var parent = ref.parent
                            var selected = ref.selected
                            return [
                              _vm.isSelectedAnima
                                ? _c(
                                    "span",
                                    [
                                      index < 8
                                        ? _c(
                                            "v-chip",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "input-value": selected
                                                }
                                              },
                                              "v-chip",
                                              attrs,
                                              false
                                            ),
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "pr-2" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.abbreviation
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { "x-small": "" },
                                                  on: {
                                                    click: function($event) {
                                                      parent.selectItem(item)
                                                      _vm.checkboxAllCampuses = false
                                                    }
                                                  }
                                                },
                                                [_vm._v("close")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      index === 8
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "grey--text caption ml-1"
                                            },
                                            [
                                              _vm._v(
                                                " (+" +
                                                  _vm._s(
                                                    _vm.chipsCampuses.length - 8
                                                  ) +
                                                  " outros) "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _c(
                                    "span",
                                    [
                                      item === Object(item)
                                        ? _c(
                                            "v-chip",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "input-value": selected
                                                }
                                              },
                                              "v-chip",
                                              attrs,
                                              false
                                            ),
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "pr-2" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.abbreviation
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { small: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return parent.selectItem(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("close")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.chipsCampuses,
                      callback: function($$v) {
                        _vm.chipsCampuses = $$v
                      },
                      expression: "chipsCampuses"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "warning" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.selecionarPeriodo()
                        }
                      }
                    },
                    [_vm._v(" Selecionar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("emc-alert-snackbar", {
        attrs: { show: _vm.show, message: _vm.message, color: _vm.color },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        }
      }),
      _c("emc-loader-progress", { attrs: { show: _vm.loading } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }